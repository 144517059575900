import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { Explore as ExploreIcon, Search as PageviewIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import QCXSimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/QCXSimpleConsultPageTemplate';
import SimpleConsultPageTemplate from '../../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import {
  activateByIdAsync,
  inactivateByIdAsync,
  fetchAllAsync,
  fetchPaginateAsync,
  selectEmpresasEstrangeiras,
  selectEmpresaEstrangeiraCount,
  resetMode,
  resetBackgroundMode,
  resetModel,
} from '../../../../../features/empresa-estrangeira/empresaEstrangeiraSlice';
import {
  changeControlTo as changeControlPaisTo,
  resetList as resetPaisList,
} from '../../../../../features/pais/paisSlice';
import { resetList as resetEstadoList } from '../../../../../features/estado/estadoSlice';
import { resetList as resetCidadeList } from '../../../../../features/cidade/cidadeSlice';
import { ALTERNATIVE_LOADING_STATUS } from '../../../../../features/status';
import EmpresaEstrangeiraContext from '../../../../../contexts/registrations/empresa-estrangeira/EmpresaEstrangeiraContext';
import { IDLE_CONTROL } from '../../../../../features/config-control';
import { userHasDeleteRole } from '../../../../../components/perfil/perfil-utils';
import { getLocalidadeOrDefault } from '../../../../../utils/general/general-utils';
import TableCellWithTooltip from '../catalogo-produtos/components/TableCellWithTooltip';

export default function EmpresaEstrangeiraConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(EmpresaEstrangeiraContext);

  const dispatch = useDispatch();

  const empresasEstrangeiras = useSelector(selectEmpresasEstrangeiras);

  const isAlternativeLoading = useMemo(() => status === ALTERNATIVE_LOADING_STATUS, [status]);

  const fetchAll = async () => {
    // dispatch(fetchAllAsync());
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  };

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
  }, []);

  const resetDependencies = useCallback(() => {
    dispatch(changeControlPaisTo(IDLE_CONTROL));
    dispatch(resetPaisList());
    dispatch(resetEstadoList());
    dispatch(resetCidadeList());
  }, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, []);

  const breadcrumbs = [
    {
      link: {
        to: '/',
        name: t('com.muralis.qcx.inicio'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.cadastros'),
      },
    },
    {
      link: {
        to: t('com.muralis.qcx.url.moduloOperacionais'),
        name: t('com.muralis.qcx.operacionais'),
      },
    },
    {
      text: {
        name: t('com.muralis.qcx.empresaEstrangeira.labelPlural'),
      },
    },
  ];

  const handleActivateInactivate = async (event, id) => {
    event.preventDefault();

    const { active } = empresasEstrangeiras.find((empresaEstrangeira) => empresaEstrangeira.id === id);

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleClickOnEditButton = useCallback(
    (_event, id) => history.push(t('com.muralis.qcx.url.moduloOperacionaisEmpresasEstrangeiraDetalhes', { id })),
    [history]
  );

  const columns = [
    {
      field: 'id',
      headerName: t('com.muralis.qcx.codigo'),
      headerAlign: 'center',
      align: 'center',
      flex: 110,
      valueGetter: ({ row }) => row?.id,
    },
    {
      field: 'pessoaJuridica',
      headerName: t('com.muralis.qcx.empresa.razaoSocial'),
      type: 'string',
      flex: 275,
      valueGetter: ({
        row: {
          pessoaJuridica: { nome },
        },
      }) => nome,
    },
    {
      field: 'localidade',
      headerName: t('com.muralis.qcx.localidade'),
      type: 'string',
      flex: 165,
      valueGetter: ({ row }) => getLocalidadeOrDefault(row?.endereco, '-'),
      renderCell: ({ row }) => <TableCellWithTooltip value={getLocalidadeOrDefault(row?.endereco, '-')} />,
    },
    {
      field: 'importador',
      headerName: t('com.muralis.qcx.importador.label'),
      type: 'boolean',
      flex: 100,
    },
    {
      field: 'exportador',
      headerName: t('com.muralis.qcx.exportador.label'),
      type: 'boolean',
      flex: 100,
    },
    {
      field: 'fabricante',
      headerName: t('com.muralis.qcx.fabricante.label'),
      type: 'boolean',
      flex: 100,
    },

    //
    {
      field: 'exportadorFilter',
      headerName: t('com.muralis.qcx.exportador.label'),
      hide: true,
      flex: 135,
      valueGetter: ({ row }) =>
        row?.exportador
          ? t('com.muralis.qcx.exportador.eExportador').toLowerCase()
          : t('com.muralis.qcx.exportador.naoExportador').toLowerCase(),
    },
    {
      field: 'importadorFilter',
      headerName: t('com.muralis.qcx.importador.label'),
      flex: 135,
      hide: true,
      valueGetter: ({ row }) =>
        row?.importador
          ? t('com.muralis.qcx.importador.eImportador').toLowerCase()
          : t('com.muralis.qcx.importador.naoImportador').toLowerCase(),
    },
    {
      field: 'fabricanteFilter',
      headerName: t('com.muralis.qcx.fabricante.label'),
      flex: 135,
      hide: true,
      valueGetter: ({ row }) =>
        row?.fabricante
          ? t('com.muralis.qcx.fabricante.eFabricante').toLowerCase()
          : t('com.muralis.qcx.fabricante.naoFabricante').toLowerCase(),
    },
    {
      field: 'actions',
      headerName: t('com.muralis.qcx.acoes.label'),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
      renderCell: ({ row: { id, active } }) => (
        <>
          {userHasDeleteRole(authInfo, 'exportador-fabricante') && (
            <IconButton
              key={`stw-active${id}`}
              value={active}
              name={`stw-active-${id}`}
              onClick={(event) => handleActivateInactivate(event, id)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          )}
          <IconButton
            key={`btn-view-${id}`}
            name={`btn-view-${id}`}
            onClick={(event) => handleClickOnEditButton(event, id)}
          >
            <PageviewIcon color="primary" />
          </IconButton>
        </>
      ),
    },
  ];

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    // dispara essa funcao sempre que a pagina é alterada ou alguma outra config
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectEmpresaEstrangeiraCount);

  const templateProps = {
    page: {
      title: t('com.muralis.qcx.empresaEstrangeira.labelPlural'),
      icon: <ExploreIcon />,
      breadcrumbs,
    },
    control: {
      buttonNew: {
        description: t('com.muralis.qcx.empresaEstrangeira.novaEmpresaEstrangeira'),
        path: t('com.muralis.qcx.url.moduloOperacionaisEmpresasEstrangeiraNova'),
      },
    },
    table: {
      columns,
      rowsCount,
      onConfigChangeCallback: handleConfigChange,
    },
  };

  const filterPropGetter = useCallback((empresaEstrangeira) => empresaEstrangeira.pessoaJuridica.nome, []);

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={empresasEstrangeiras}
      isLoading={isAlternativeLoading}
      filterPropGetter={filterPropGetter}
      buttonNewText={t('com.muralis.qcx.acoes.nova').toUpperCase()}
      authInfo={authInfo}
      requiredRoles={['exportador-fabricante']}
    />
  );
}
