import { saveAs } from 'file-saver';
import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { MediaType } from '../../utils/api/api-utils';
import {
  activateById,
  fetchAll,
  fetchById,
  inactivateById,
  register,
  save,
  send,
  baixarXmlNfe,
  baixarPdfDanfe,
  fetchByFilter,
  fetchPaginateAsync as fetchPaginateAsyncAPI,
  consultaStatusNfe,
  fetchNotasDanfeByProcesso,
  assinar,
  baixarXmlNfeLote,
  baixarXmlAssinadoNfeLote,
  assinarNfeLote,
} from './danfeAPI';
import { setErrorFeedback, setSuccessFeedback } from '../feedback/feedbackSlice';

function stringToDate(date) {
  const m = moment(date, 'YYYY/MM/DD hh:mm:ss');
  const d = m.toDate();
  return d;
}

const fetchAllAsync = createAsyncThunk('danfe/fetchAll', async () => {
  const response = await fetchAll();
  const list = response.data;
  if (Array.isArray(list)) {
    // Coloca as danfes mais recentes no início.
    list.sort((a, b) => stringToDate(b.insertionDate) - stringToDate(a.insertionDate));
  }
  return {
    response: {
      status: response.status,
      data: list ?? response.data,
    },
  };
});

const fetchByIdAsync = createAsyncThunk('danfe/fetchById', async (id) => {
  const response = await fetchById(id);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const registerAsync = createAsyncThunk('danfe/register', async (data, queryParams) => {
  const response = await register(data, queryParams);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const saveAsync = createAsyncThunk('danfe/save', async (data, queryParams) => {
  const response = await save(data, queryParams);
  return {
    response: {
      status: response.status,
      data: response.data,
    },
  };
});

const activateByIdAsync = createAsyncThunk('danfe/activateById', async (id) => {
  const { status, data } = await activateById(id);
  return { response: { status, data } };
});

const inactivateByIdAsync = createAsyncThunk('danfe/inactivateById', async (id) => {
  const { status, data } = await inactivateById(id);
  return { response: { status, data } };
});

const sendDanfeAsync = createAsyncThunk('danfe/sendDanfeAsync', async (id) => {
  try {
    const { status, data } = await send(id);
    return { response: { status, data } };
  } catch (e) {
    const { response } = e;
    if (response?.data?.message) {
      throw Error(response.data.message);
    } else {
      throw e;
    }
  }
});

const assinarDanfeAsync = createAsyncThunk('danfe/assinarDanfeAsync', async (id) => {
  try {
    const { status, data } = await assinar(id);
    return { response: { status, data } };
  } catch (e) {
    const { response } = e;
    if (response?.data?.message) {
      throw Error(response.data.message);
    } else {
      throw e;
    }
  }
});

const baixarXmlAsync = createAsyncThunk('danfe/baixarXmlAsync', async (id) => {
  try {
    // Infelizmente, o axios não baixa o arquivo automaticamente,
    // embora os headers estejam corretos.
    const { data } = await baixarXmlNfe(id);

    const xmlData = new Blob([data], { type: MediaType.APPLICATION_XML });

    // Esperamos que data seja um Blob, pois a config da request especifica que é.
    const url = window.URL.createObjectURL(xmlData);
    window.open(url); // <- Deve abrir um prompt para salvar.
  } catch (e) {
    const { response } = e;

    if (response?.data && response?.data instanceof Blob) {
      const blob = response?.data;

      const parsedData = await new Promise((resolve) => {
        const fr = new FileReader();

        fr.onload = ({ target }) => resolve(JSON.parse(target?.result));

        fr.readAsText(blob);
      });

      throw Error(parsedData?.message);
    } else {
      throw e;
    }
  }
});

const baixarXMLAsyncDanfeLote = createAsyncThunk(
  'danfe/baixarXMLAsyncDanfeLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await baixarXmlNfeLote(data);

      if (response.status === 200) {
        dispatch(setSuccessFeedback({ message: 'XMLs baixados com sucesso!' }));

        saveAs(new Blob([response.data], { type: 'application/zip' }), 'xml.zip');

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      return {
        response: {
          status: response.status,
          data: response.data,
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const baixarXMLAssinadoAsyncDanfeLote = createAsyncThunk(
  'danfe/baixarXMLAssinadoAsyncDanfeLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await baixarXmlAssinadoNfeLote(data);

      if (response.status === 200) {
        dispatch(setSuccessFeedback({ message: 'XMLs baixados com sucesso!!' }));

        saveAs(new Blob([response.data], { type: 'application/zip' }), 'xml.zip');

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      return {
        response: {
          status: response.status,
          data: response.data,
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const assinarAsyncDanfeLote = createAsyncThunk(
  'danfe/assinarAsyncDanfeLote',
  async ({ data, successCallback }, { rejectWithValue, dispatch }) => {
    try {
      const response = await assinarNfeLote(data);

      if (response.status === 200 && response.data) {
        dispatch(setSuccessFeedback({ message: response.data }));

        if (typeof successCallback === 'function') {
          successCallback();
        }
      }

      return {
        response: {
          status: response.status,
          data: response.data,
        },
      };
    } catch (error) {
      dispatch(setErrorFeedback({ message: error?.response?.data }));
      return rejectWithValue(error?.response?.data);
    }
  }
);

const consultaStatusNfeAsync = createAsyncThunk('danfe/consultaStatusNfeAsync', async (id, { dispatch }) => {
  try {
    const { data } = await consultaStatusNfe(id);
    return data;
  } catch (e) {
    dispatch(setErrorFeedback({ message: 'Erro ao consultar status da NFe.' }));
    return null;
  }
});

const printDanfeAsync = createAsyncThunk('danfe/printDanfeAsync', async (id) => {
  try {
    // Infelizmente, o axios não baixa o arquivo automaticamente,
    // embora os headers estejam corretos.
    const { data } = await baixarPdfDanfe(id);

    const file = new File([data], `danfe-${id}.pdf`, {
      type: MediaType.APPLICATION_PDF,
    });

    // Esperamos que data seja um Blob, pois a config da request especifica que é.
    const url = window.URL.createObjectURL(file);

    const a = document.createElement('a');
    a.href = url;
    a.download = file.name;
    a.click();
  } catch (e) {
    const { response } = e;

    if (response?.data && response?.data instanceof Blob) {
      const blob = response?.data;

      const parsedData = await new Promise((resolve) => {
        const fr = new FileReader();

        fr.onload = ({ target }) => resolve(JSON.parse(target?.result));

        fr.readAsText(blob);
      });

      throw Error(parsedData?.message);
    } else {
      throw e;
    }
  }
});

const fetchNotasDanfeByProcessoAsync = createAsyncThunk('danfe/fetchNotasDanfeByProcesso', async ({ processo }) => {
  const params = [{ name: 'processo', value: processo }];

  const { data } = await fetchNotasDanfeByProcesso(params);
  return { data };
});

const fetchByFilterAsync = createAsyncThunk('danfe/fetchByFilter', async (params) => {
  const { data } = await fetchByFilter(params);
  return { data };
});

const fetchPaginateAsync = createAsyncThunk('danfe/fetchPaginateAsync', async (params) => {
  const { data } = await fetchPaginateAsyncAPI(params);
  return { data };
});

export {
  fetchAllAsync,
  fetchByIdAsync,
  registerAsync,
  saveAsync,
  activateByIdAsync,
  inactivateByIdAsync,
  sendDanfeAsync,
  baixarXmlAsync,
  printDanfeAsync,
  fetchByFilterAsync,
  fetchPaginateAsync,
  consultaStatusNfeAsync,
  fetchNotasDanfeByProcessoAsync,
  assinarDanfeAsync,
  baixarXMLAsyncDanfeLote,
  baixarXMLAssinadoAsyncDanfeLote,
  assinarAsyncDanfeLote,
};
