/* eslint-disable */
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import {
  CancelOutlined as CancelOutlinedIcon,
  CheckCircle as CheckCircleIcon,
  Code as CodeIcon,
  Delete as DeleteIcon,
  GetApp as DownloadIcon,
  FileCopy as FileCopyIcon,
  Group as GroupIcon,
  MoreHoriz as MoreHorizIcon,
  Search as PageviewIcon,
  Print as PrintIcon,
  Publish as PublishIcon,
  Receipt as ReceiptIcon,
  RotateLeft as RotateLeftIcon,
} from '@material-ui/icons';
import { isEmpty, isFunction } from 'lodash';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { userHasDeleteRole } from '../../../../components/perfil/perfil-utils';
import DanfeContext from '../../../../contexts/import/danfe/DanfeContext';
import {
  selectCountDanfe,
  selectDanfes,
  selectMode,
  selectRefresh,
  selectRelatedDanfeSelectionItemList,
} from '../../../../features/danfe/danfeSelectors';
import {
  changeToConsultMode,
  changeToCreateMode,
  changeToRegisterModeDanfe,
  failure,
  loading,
  refresh as refreshList,
  resetBackgroundMode,
  resetMode,
  resetModel,
  resetRefresh,
  resetRelatedDanfeMae,
  resetRelatedSelectionAvailableItemList,
  resetRelatedSelectionChangedItemList,
  resetRelatedSelectionItemList,
  resetRelatedTransferenciaItem,
  setRelatedSelectionDanfeList,
  success,
} from '../../../../features/danfe/danfeSlice';
import SimpleConsultPageTemplate from '../../../../templates/simple-consult-page/SimpleConsultPageTemplate/SimpleConsultPageTemplate';
import { normalizeData, unnormalizeData } from '../../../../utils/general/general-utils';
import { isAlternativeLoadingStatus, isNoneMode, isRefresh, isRegisterMode } from '../../../../utils/store/store-utils';

import {
  activateByIdAsync,
  assinarDanfeAsync,
  baixarXMLAsyncDanfeLote,
  consultaStatusNfeAsync,
  fetchPaginateAsync,
  inactivateByIdAsync,
  printDanfeAsync,
  sendDanfeAsync,
  baixarXMLAssinadoAsyncDanfeLote,
  assinarAsyncDanfeLote,
} from '../../../../features/danfe/danfeThunks';

import { useKeycloak } from '@react-keycloak/web';
import QCXPopupState from '../../../../components/popup-state/QCXPopupState';
import { danfeAdicaoMovimentacaoAPI } from '../../../../features/danfe-adicao-movimentacao/danfeAdicaoMovimentacaoAPI';
import { setErrorFeedback, setSuccessFeedback, setWarningFeedback } from '../../../../features/feedback/feedbackSlice';
import { selectUnidadeSelecionada } from '../../../../features/usuario-logado/usuarioLogadoSlice';
import QCXButton from '../../../../shared-components/button/QCXButton';
import QCXConfirmDialog from '../../../../shared-components/dialog/QCXConfirmDialog';
import QCXListItem from '../../../../shared-components/list-item-icon/QCXListItemIcon';
import useConfigurePopUp from '../../../../ts/common/hooks/popUps/useConfigurePopUp';
import { useGenerateDanfeFileReport } from '../../../../ts/common/hooks/useGenerateDANFEXML';
import { generateTxt } from '../../../../ts/pages/integracao/croda/escrita/crodaEscrita.helpers';
import {
  criaSolicitacaoSaldoItens,
  DANFE_ASSINADA,
  DANFE_COMPLEMENTAR,
  DANFE_EMITIDA,
  DANFE_ERRO,
  DANFE_FILHOTE,
  DANFE_MAE,
  DANFE_NAO_EMITIDA,
  DANFE_TRANSMITIDA,
  isDanfeEmitida,
  isDanfeFilhote,
  isDanfeMae,
  isDanfeNaoEmitida,
} from '../../../../utils/general/danfe/danfeUtils';
import { OPERACAO_ESTORNO } from '../../../../utils/general/movimentacao/movimentacaoUtils';
import useOperationMiddleware from '../../../../utils/hooks/operation/middleware/useOperationMiddleware';

const formatDate = (date, inputFormat = 'YYYY-MM-DD', dateFormat = 'DD/MM/YYYY') =>
  normalizeData(unnormalizeData(date, inputFormat), dateFormat);

export default function DanfeConsultPage({ authInfo = {} }) {
  const { t } = useTranslation();
  const history = useHistory();

  const { status } = useContext(DanfeContext);

  const dispatch = useDispatch();

  // Get variables through selectors
  const danfes = useSelector(selectDanfes);
  const refresh = useSelector(selectRefresh);

  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  const fetchAll = useCallback(() => {
    // dispatch(fetchByFilterAsync([{ name: 'active', value: true }]));
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: 1 },
        { name: 'size', value: 10 },
      ])
    );
  }, []);

  const resetModes = useCallback(() => {
    dispatch(resetMode());
    dispatch(resetBackgroundMode());
  }, []);

  const resetModels = useCallback(() => {
    dispatch(resetModel());
    dispatch(resetRelatedSelectionAvailableItemList());
    dispatch(resetRelatedSelectionChangedItemList());
    dispatch(resetRelatedSelectionItemList());
    dispatch(resetRelatedTransferenciaItem());
    dispatch(resetRelatedDanfeMae());
  }, []);

  const resetDependencies = useCallback(() => {}, []);

  useEffect(() => {
    fetchAll();
    resetModes();
    resetModels();
    resetDependencies();
  }, [fetchAll, resetModes, resetModels, resetDependencies]);

  const [tipoEvento, setTipoEvento] = useState();

  const mode = useSelector(selectMode);
  const isRegister = useMemo(() => isRegisterMode(mode), [mode]);
  const isNone = useMemo(() => isNoneMode(mode), [mode]);

  const relatedSelectionDanfeList = useSelector(selectRelatedDanfeSelectionItemList);

  const isEmptySelectionList = useMemo(
    () =>
      !relatedSelectionDanfeList ||
      (_.isArrayLikeObject(relatedSelectionDanfeList) && _.isEmpty(relatedSelectionDanfeList)),
    [relatedSelectionDanfeList]
  );

  const handleSelectionModelChange = useCallback(({ selectionModel }) => {
    if (true) {
      dispatch(setRelatedSelectionDanfeList(selectionModel));
    }
  }, []);

  useEffect(() => {
    if (isRefresh(refresh)) {
      fetchAll();
      dispatch(resetRefresh());
    }
  }, [fetchAll, refresh]);

  const handleButtonNewClick = useCallback((event) => {
    event.stopPropagation?.();
    dispatch(resetModel());
    dispatch(changeToCreateMode());
  }, []);

  const handleClickOnEditButton = useCallback(
    (event, id) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
      }

      dispatch(changeToConsultMode());
      history.push(t('com.muralis.qcx.url.importacaoDANFEDetalhes', { id }));
    },
    [history]
  );

  const handleClickOnFilhoteButton = useCallback(
    async (event, danfe) => {
      if (isFunction(event?.stopPropagation)) {
        event.stopPropagation();
      }

      dispatch(loading());

      const { id, adicoes, numeroDanfe } = danfe;

      const danfeAdicaoMovimentacaoContaList = criaSolicitacaoSaldoItens(adicoes);

      const responseContasItens = await danfeAdicaoMovimentacaoAPI.fetchBalancesByOwners(
        danfeAdicaoMovimentacaoContaList
      );

      if (isEmpty(responseContasItens?.data)) {
        dispatch(success());
        dispatch(
          setWarningFeedback({
            message: t('com.muralis.qcx.mensagem.todosItensDANFEForamTransferidos', {
              numeroDanfe,
            }),
          })
        );

        return;
      }

      dispatch(resetModel());
      dispatch(changeToCreateMode());

      history.push(t('com.muralis.qcx.url.importacaoDANFENovo'), {
        data: {
          danfeMae: {
            id,
          },
        },
      });
    },
    [t, history]
  );

  const diDescription = (di) => {
    let baseDescription = di?.atributosAdicionais?.processo;
    if (di?.atributosAdicionais?.registro) {
      baseDescription = `${baseDescription} - ${di.atributosAdicionais.registro}`;
    }
    return baseDescription;
  };

  const [handleOpenPopUp, configureTransmissaoPopUp] = useConfigurePopUp({
    initialOptions: {
      title: 'A danfe foi transmitida com sucesso',
      message: 'Deseja atualizar o resultado da transmissão?',
    },
    primaryConfirmCallback: async (args) => {
      const { id } = args;
      dispatch(consultaStatusNfeAsync(id));
      dispatch(refreshList());
    },
  });

  const [handleOpenErroPopUp, configureErroPopUp] = useConfigurePopUp({
    initialOptions: {
      title: 'Erro na transmissão da DANFE',
    },
  });

  const statusNfeDescription = useCallback(
    (row) => {
      const { statusNfe } = row;

      if (!statusNfe) {
        return '';
      }

      switch (statusNfe) {
        case DANFE_NAO_EMITIDA:
          return <Typography variant="inherit">{t('com.muralis.qcx.estado.naoEmitida')}</Typography>;
        case DANFE_ASSINADA:
          return <Typography variant="inherit">{t('com.muralis.qcx.estado.assinada')}</Typography>;
        case DANFE_TRANSMITIDA:
          return (
            <QCXButton
              tooltip
              tooltipDescription={t('com.muralis.qcx.resultadoTransmissao')}
              onClick={() => handleOpenPopUp({ args: row })}
              fullWidth
              variant="default"
            >
              <Typography
                variant="inherit"
                style={{
                  fontSize: 14,
                }}
              >
                {t('com.muralis.qcx.estado.transmitida')}
              </Typography>
            </QCXButton>
          );
        case DANFE_EMITIDA:
          return <Typography variant="inherit">{t('com.muralis.qcx.estado.emitida')}</Typography>;
        case DANFE_ERRO:
          return (
            <QCXButton
              tooltip
              tooltipDescription={t('com.muralis.qcx.resultadoTransmissao')}
              onClick={() => handleOpenErroPopUp({ options: { message: row?.mensagemErroEnvio } })}
              fullWidth
              variant="default"
            >
              <Typography
                variant="inherit"
                style={{
                  fontSize: 14,
                }}
              >
                {t('com.muralis.qcx.estado.erro')}
              </Typography>
            </QCXButton>
          );
        default:
          return '';
      }
    },
    [t]
  );

  const tipoDanfeDescription = useCallback(
    (tipoDanfe) => {
      if (!tipoDanfe) {
        return '';
      }

      switch (tipoDanfe) {
        case DANFE_MAE:
          return t('com.muralis.qcx.entrada');
        case DANFE_FILHOTE:
          return t('com.muralis.qcx.filhote');
        case DANFE_COMPLEMENTAR:
          return t('com.muralis.qcx.acoes.complementar');
        default:
          return '';
      }
    },
    [t]
  );

  const handleActivateInactivate = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const { active } = danfes.find((item) => item.id === id);

    const isActivation = !active;

    if (isActivation) {
      dispatch(activateByIdAsync(id));
    } else {
      dispatch(inactivateByIdAsync(id));
    }
  };

  const handleSend = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    dispatch(sendDanfeAsync(id));
  };

  const handleAssinar = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    dispatch(assinarDanfeAsync(id));
  };

  const { keycloak } = useKeycloak();
  const selectedUnidade = useSelector(selectUnidadeSelecionada);
  const { token } = keycloak;

  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [idDanfe, setIdDanfe] = useState(null);
  const [typeFile, setTypeFile] = useState('xml');
  const [selectedDi, setSelectedDi] = useState(null);
  const { data: reportData } = useGenerateDanfeFileReport(idDanfe, typeFile);

  const handleDownloadFile = useCallback((event, id, di, typeFile) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    setIdDanfe(id);
    setTypeFile(typeFile);
    setHasDownloaded(false);
    setSelectedDi(di);
  }, []);

  const handleDownloadTxt = async (event, id, di) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    const url =
      process.env.REACT_APP_QCX_API_BASE_URL + '/' + process.env.REACT_APP_QCX_API_DANFE_ENDPOINT + '/download-txt';

    if (id && di && token && selectedUnidade) {
      const data = await generateTxt(token, selectedUnidade.toString(), `${url}/${id}`, 'file');

      if (data.statusCode === 500 || data.statusCode === 404) {
        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: data.message,
          })
        );
      }

      if (data.statusCode === 200) {
        const blob = data.file;
        saveAs(blob, `${id?.atributosAdicionais?.processo ?? 'Danfe'}.txt`);
      }

      dispatch(refreshList());
    }
  };

  const memoizedReportData = useMemo(() => reportData, [reportData]);

  useEffect(() => {
    if (memoizedReportData && !hasDownloaded) {
      const danfeEncontrada = danfes.find((danfe) => danfe.di.id === selectedDi.id);

      const codigoDanfe = danfeEncontrada ? danfeEncontrada.code : 'Danfe';

      saveAs(memoizedReportData, `${codigoDanfe}-${typeFile}.xml`);

      setHasDownloaded(true);
    }
  }, [memoizedReportData]);

  const handlePrint = async (event, id) => {
    event?.preventDefault?.();
    event?.stopPropagation?.();

    dispatch(printDanfeAsync(id));
  };

  const [handleEstornarDanfeFilhoteMiddleware, operationOfEstornarDanfeFilhote] = useOperationMiddleware(
    async (data) => {
      dispatch(loading());

      const payload = [
        {
          operacao: {
            description: OPERACAO_ESTORNO,
          },
          filhote: {
            id: data?.id,
          },
        },
      ];

      const response = await danfeAdicaoMovimentacaoAPI.reverse(payload).catch((reason) => {
        const responseMessage = reason?.response?.data?.message;

        const mensagem = responseMessage ? `: ${responseMessage}.` : '.';

        const errorMessage = t('com.muralis.qcx.erro.erroEstornoDANFEFilhote', {
          numeroDanfe: data?.numeroDanfe,
          mensagem,
        });

        dispatch(failure());
        dispatch(
          setErrorFeedback({
            message: errorMessage,
          })
        );
      });

      if (response?.status === 201) {
        dispatch(
          setSuccessFeedback({
            message: t('com.muralis.qcx.mensagem.estornoDANFEFilhote', {
              numeroDanfe: data?.numeroDanfe,
            }),
          })
        );

        dispatch(refreshList());
      }
    },
    [t]
  );

  const handleConfigureEstornarDanfeFilhoteClick = useCallback(
    (row, popupState) => {
      const { id, numeroDanfe } = row;

      const handleEstornarDanfeFilhoteClick = (event) => {
        if (isFunction(event?.stopPropagation)) {
          popupState.close(event);

          event.stopPropagation();

          const configureOperation = () => ({
            options: {
              title: t('com.muralis.qcx.confirmarOperacaoEstorno').toUpperCase(),
              message: t('com.muralis.qcx.mensagem.confirmarOperacaoEstornoDANFEFilhote', {
                numeroDanfe,
              }),
              endMessage: t('com.muralis.qcx.acoes.confirmacaoAcao', {
                acao: t('com.muralis.qcx.acoes.estornar').toLowerCase(),
              }),
            },
          });

          const dataOfOperationEstornarDanfeFilhote = {
            id,
            numeroDanfe,
          };

          handleEstornarDanfeFilhoteMiddleware(configureOperation, dataOfOperationEstornarDanfeFilhote);
        }
      };

      return handleEstornarDanfeFilhoteClick;
    },
    [t, handleEstornarDanfeFilhoteMiddleware]
  );

  const breadcrumbs = useMemo(
    () => [
      {
        link: {
          to: '/',
          name: t('com.muralis.qcx.inicio'),
        },
      },
      {
        link: {
          to: t('com.muralis.qcx.url.moduloImportacao'),
          name: t('com.muralis.qcx.importacao.label'),
        },
      },
      {
        text: {
          name: t('com.muralis.qcx.DANFE.label'),
        },
        default: true,
      },
    ],
    [t]
  );

  const columns = useMemo(
    () => [
      {
        field: 'code',
        headerName: t('com.muralis.qcx.codigo'),
        headerAlign: 'center',
        align: 'left',
        flex: 5,
        valueGetter: ({ row }) => row?.code,
      },
      {
        field: 'di',
        headerName: t('com.muralis.qcx.DIDUIMP.label'),
        headerAlign: 'center',
        align: 'left',
        flex: 5,
        valueGetter: ({ row }) => diDescription(row?.di),
      },
      {
        field: 'importador',
        headerName: t('com.muralis.qcx.importador.label'),
        headerAlign: 'center',
        align: 'left',
        flex: 6,
        valueGetter: ({ row }) => row?.importador?.pessoa?.nome || '-',
      },
      {
        field: 'numero',
        headerName: t('com.muralis.qcx.DANFE.numeroDANFE'),
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        valueGetter: ({ row }) => row?.numeroDanfe,
      },
      {
        field: 'modelo',
        headerName: t('com.muralis.qcx.DANFE.modeloDANFE'),
        headerAlign: 'center',
        align: 'left',
        flex: 4,
        valueGetter: ({ row }) => row?.importador?.modeloDanfe?.nome || '-',
      },
      {
        field: 'status',
        headerName: t('com.muralis.qcx.DANFE.NFStatus'),
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        renderCell: ({ row }) => statusNfeDescription(row),
      },
      {
        field: 'tipo',
        headerName: t('com.muralis.qcx.DANFE.tipoDANFE'),
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        valueGetter: ({ row }) => tipoDanfeDescription(row?.tipoDanfe),
      },
      {
        field: 'dtEmissao',
        headerName: 'Data Emissão',
        headerAlign: 'center',
        align: 'center',
        flex: 3,
        valueGetter: ({ row }) => {
          let strDate = row?.dataEmissao;
          if (!strDate) return '';
          return formatDate(strDate);
        },
      },
      {
        field: 'actions',
        headerName: t('com.muralis.qcx.acoes.label'),
        headerAlign: 'center',
        align: 'left',
        flex: 3,
        renderCell: ({ row: { id, active, statusNfe, tipoDanfe, numeroDanfe, adicoes, di } }) => (
          <>
            <QCXPopupState popupId="popup-menu-danfe-consult">
              {(popupState) => (
                <>
                  <IconButton
                    key={`btn-more-options-${id}`}
                    name={`btn-more-options-${id}`}
                    {...bindTrigger(popupState)}
                  >
                    <MoreHorizIcon color="secondary" size={20} />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    {/** Item 1: Visualizar */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleClickOnEditButton(event, id);
                      }}
                    >
                      <QCXListItem>
                        <PageviewIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.consultar').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 1: Assinar */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleAssinar(event, id);
                      }}
                    >
                      <QCXListItem>
                        <FileCopyIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Assinar'.toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 2: Transmitir (assina automaticamente) */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleSend(event, id);
                      }}
                      disabled={isDanfeEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <PublishIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.transmitir').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 3: Baixar xml */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleDownloadFile(event, id, di, 'xml');
                      }}
                    >
                      <QCXListItem>
                        <CodeIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.baixarXML').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 3: Baixar xml assinado*/}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleDownloadFile(event, id, di, 'assinada');
                      }}
                      disabled={isDanfeNaoEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <CodeIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {'Baixar XML Assinado'.toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 3: Baixar txt */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handleDownloadTxt(event, id, di, 'txt');
                      }}
                    >
                      <QCXListItem>
                        <CodeIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.baixarTxt').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 4: Imprimir */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handlePrint(event, id);
                      }}
                      disabled={isDanfeNaoEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <PrintIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.imprimir').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 5: Espelhar (= visualizar pdf, mesmo sem enviar) */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);

                        handlePrint(event, id);
                      }}
                      disabled={isDanfeEmitida(statusNfe)}
                    >
                      <QCXListItem>
                        <DownloadIcon fontSize="small" color="primary" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.DANFE.espelhoDANFE').toUpperCase()}
                      </Typography>
                    </MenuItem>

                    {/** Item 6: Criar Filhote */}
                    {isDanfeMae(tipoDanfe) && (
                      <MenuItem
                        onClick={(event) => {
                          popupState.close(event);

                          handleClickOnFilhoteButton(event, {
                            id,
                            adicoes,
                            numeroDanfe,
                          });
                        }}
                      >
                        <QCXListItem>
                          <GroupIcon fontSize="small" color="primary" />
                        </QCXListItem>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.filhote').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}

                    {/** Item 7: Estornar Filhote */}
                    {isDanfeFilhote(tipoDanfe) && (
                      <MenuItem
                        onClick={handleConfigureEstornarDanfeFilhoteClick(
                          {
                            id,
                            numeroDanfe,
                          },
                          popupState
                        )}
                        disabled={isDanfeEmitida(statusNfe)}
                      >
                        <QCXListItem>
                          <RotateLeftIcon fontSize="small" color="primary" />
                        </QCXListItem>
                        <Typography
                          variant="inherit"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          {t('com.muralis.qcx.acoes.estornar').toUpperCase()}
                        </Typography>
                      </MenuItem>
                    )}
                    {/** Item 9: Desativar */}
                    <MenuItem
                      onClick={(event) => {
                        popupState.close(event);
                        handleActivateInactivate(event, id);
                      }}
                      disabled={isDanfeEmitida(statusNfe) || !userHasDeleteRole(authInfo, 'danfe') || !active}
                    >
                      <QCXListItem>
                        <DeleteIcon fontSize="small" color="error" />
                      </QCXListItem>
                      <Typography
                        variant="inherit"
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {t('com.muralis.qcx.acoes.remover').toUpperCase()}
                      </Typography>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </QCXPopupState>
          </>
        ),
      },
    ],
    [
      t,
      diDescription,
      handleSend,
      handlePrint,
      handleDownloadFile,
      statusNfeDescription,
      tipoDanfeDescription,
      handleClickOnEditButton,
      handleConfigureEstornarDanfeFilhoteClick,
    ]
  );

  const handleConfigChange = useCallback(({ currentPage, pageSize, filterInputValue }) => {
    dispatch(
      fetchPaginateAsync([
        { name: 'page', value: currentPage },
        { name: 'size', value: pageSize },
        { name: 'numero', value: filterInputValue },
      ])
    );
  }, []);

  const rowsCount = useSelector(selectCountDanfe);

  // REGISTER
  const handleEnableRegisterMode = useCallback((event, tipoEvento) => {
    setTipoEvento(tipoEvento); // 'ASSINAR_LOTE', 'BAIXAR_XML_ASSINADO_LOTE', 'BAIXAR_XML_LOTE'
    event?.stopPropagation();
    dispatch(changeToRegisterModeDanfe());
  });

  const handleCancelSelectOperation = useCallback((event) => {
    event?.stopPropagation();
    dispatch(resetMode());
  }, []);

  const handleSuccessBulkOperation = useCallback(() => {
    dispatch(resetMode());
    dispatch(setRelatedSelectionDanfeList([]));
    fetchAll();
  });

  const handleConfirmRegister = useCallback(
    (event) => {
      event?.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const selectedDanfes = danfes.filter((danfe) => relatedSelectionDanfeList.includes(danfe.id));

      const payloadToDownload = selectedDanfes.map((danfe) => ({
        id: danfe.id,
      }));

      dispatch(loading());
      dispatch(baixarXMLAsyncDanfeLote({ data: payloadToDownload, successCallback: handleSuccessBulkOperation }));
    },
    [relatedSelectionDanfeList, danfes, isEmptySelectionList]
  );

  // EVENTOS EM LOTE

  const handleAssinarDanfesLote = useCallback(
    (event) => {
      event?.stopPropagation(); // stop

      if (isEmptySelectionList) {
        return;
      }

      const selectedDanfes = danfes.filter((danfe) => relatedSelectionDanfeList.includes(danfe.id));

      const payloadToDownload = selectedDanfes.map((danfe) => ({
        id: danfe.id,
      }));

      dispatch(loading());
      dispatch(assinarAsyncDanfeLote({ data: payloadToDownload, successCallback: handleSuccessBulkOperation }));
    },
    [relatedSelectionDanfeList, danfes, isEmptySelectionList]
  );

  const handleConfirmBaixarXmlAssinadoLote = useCallback(
    (event) => {
      event?.stopPropagation();

      if (isEmptySelectionList) {
        return;
      }

      const selectedDanfes = danfes.filter((danfe) => relatedSelectionDanfeList.includes(danfe.id));

      const payloadToDownload = selectedDanfes.map((danfe) => ({
        id: danfe.id,
      }));

      dispatch(loading());
      dispatch(
        baixarXMLAssinadoAsyncDanfeLote({ data: payloadToDownload, successCallback: handleSuccessBulkOperation })
      );
    },
    [relatedSelectionDanfeList, danfes, isEmptySelectionList]
  );

  const eventosEmLote = {
    ASSINAR_LOTE: {
      action: handleAssinarDanfesLote,
    },
    BAIXAR_XML_ASSINADO_LOTE: {
      action: handleConfirmBaixarXmlAssinadoLote,
    },
    BAIXAR_XML_LOTE: {
      action: handleConfirmRegister,
    },
  };

  const handleConfirmSelection = useCallback(
    (event) => {
      const evento = tipoEvento;
      const eventoObj = eventosEmLote[`${evento}`];
      if (!eventoObj || (eventoObj && !eventoObj.action)) {
        console.log(`Evento nao encontrado !!`);
        return;
      } else if (isRegister) {
        const actionFn = eventoObj.action;
        actionFn(event);
      }
    },
    [isRegister, handleConfirmRegister]
  );

  const templateProps = useMemo(
    () => ({
      page: {
        title: t('com.muralis.qcx.DANFE.label'),
        icon: <ReceiptIcon />,
        breadcrumbs,
      },
      control: {
        ...(isNone
          ? {
              buttonNew: {
                description: t('com.muralis.qcx.DANFE.novoRegistroDANFE', { tipoDanfe: '' }),
                path: t('com.muralis.qcx.url.importacaoDANFENovo'),
                onClick: handleButtonNewClick,
              },
            }
          : {}),
        others: [
          ...(isNone
            ? [
                {
                  description: 'Assinar Lote',
                  tooltip: {
                    description: 'Assinar DANFES em lote',
                  },
                  onClick: (event) => {
                    handleEnableRegisterMode(event, 'ASSINAR_LOTE');
                  },
                },
                {
                  description: 'Baixar XML assinado Lote',
                  tooltip: {
                    description: 'Baixar XML assinado das Danfes em Lote',
                  },
                  onClick: (event) => {
                    handleEnableRegisterMode(event, 'BAIXAR_XML_ASSINADO_LOTE');
                  },
                },
                {
                  description: 'XML Lote',
                  tooltip: {
                    description: 'Baixar XML das Danfes em Lote',
                  },
                  onClick: (event) => {
                    handleEnableRegisterMode(event, 'BAIXAR_XML_LOTE');
                  },
                },
              ]
            : []),
          ...(isRegister
            ? [
                {
                  description: t('com.muralis.qcx.acoes.confirmarSelecao'),
                  startIcon: <CheckCircleIcon />,
                  color: 'secondary',
                  tooltip: {
                    description: '',
                  },
                  onClick: handleConfirmSelection,
                  disabled: isEmptySelectionList,
                },
                {
                  description: <CancelOutlinedIcon />,
                  variant: 'outlined',
                  color: 'default',
                  tooltip: {
                    description: t('com.muralis.qcx.acoes.cancelar'),
                  },
                  onClick: handleCancelSelectOperation,
                },
              ]
            : []),
        ],
      },
      table: {
        columns,
        rowsCount,
        onConfigChangeCallback: handleConfigChange,
        selectionModel: relatedSelectionDanfeList,
        onSelectionModelChange: handleSelectionModelChange,
        checkboxSelection: isRegister,
      },
    }),
    [
      columns,
      breadcrumbs,
      handleButtonNewClick,
      t,
      handleConfigChange,
      rowsCount,
      isRegister,
      isNone,
      isEmptySelectionList,
    ]
  );

  return (
    <SimpleConsultPageTemplate
      pageProps={templateProps.page}
      controlProps={templateProps.control}
      tableProps={templateProps.table}
      tableData={danfes}
      isLoading={isAlternativeLoading}
      authInfo={authInfo}
      requiredRoles={['danfe']}
    >
      <QCXConfirmDialog
        key="confirm-dialog-estornar-danfe-filhote"
        id="confirm-dialog-estornar-danfe-filhote"
        open={operationOfEstornarDanfeFilhote?.active}
        title={operationOfEstornarDanfeFilhote?.title}
        content={operationOfEstornarDanfeFilhote?.message}
        endContent={operationOfEstornarDanfeFilhote?.endMessage}
        onConfirm={operationOfEstornarDanfeFilhote.confirm}
        onClose={operationOfEstornarDanfeFilhote?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-transmissao-danfe"
        id="confirm-dialog-transmissao-danfe"
        open={configureTransmissaoPopUp?.isPopUpOpen}
        title={configureTransmissaoPopUp?.title}
        content={configureTransmissaoPopUp?.message}
        onConfirm={configureTransmissaoPopUp?.confirmPrimaryAction}
        onClose={configureTransmissaoPopUp?.reset}
      />
      <QCXConfirmDialog
        key="confirm-dialog-erro-danfe"
        id="confirm-dialog-erro-danfe"
        open={configureErroPopUp?.isPopUpOpen}
        title={configureErroPopUp?.title}
        content={configureErroPopUp?.message}
        onConfirm={configureErroPopUp?.reset}
        onClose={configureErroPopUp?.reset}
      />
    </SimpleConsultPageTemplate>
  );
}
