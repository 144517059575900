import ExcelJS from 'exceljs';
import { DraftDanfeFaxeReportItem, ReportItemsSumsFaxe } from './relatorioDraftDanfeFaxePage.types';
import saveAs from 'file-saver';

/**
 * Generate an Excel spreadsheet from the grouped data.
 * @param {DraftDanfeFaxeReportItem[][]} data - DraftDanfeFaxeReportItem[][]
 * @param {string | undefined} processo - string | undefined
 */
export const generateSpreadsheetFaxe = (data: DraftDanfeFaxeReportItem[][], processo: string | undefined) => {
  const workbook = new ExcelJS.Workbook();

  data.forEach((group) => {
    const worksheet = workbook.addWorksheet(`Danfe ${group[0].numero_danfe}`);

    for (let rowIndex = 1; rowIndex <= 6; rowIndex++) {
      const row = worksheet.addRow(new Array(46).fill(''));
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };
      });
    }

    worksheet.mergeCells('D3:G3');
    worksheet.getCell('D3').value = 'Draft Para Emissão de Danfe - Importação';
    worksheet.getCell('D3').alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell('D3').font = { name: 'Arial', bold: true, size: 9 };

    headerConfig.forEach(({ header, mergeRange }) => {
      worksheet.mergeCells(mergeRange);
      const cell = worksheet.getCell(mergeRange.split(':')[0]);
      cell.value = header;
      cell.font = { name: 'Arial', bold: true, size: 9, color: { argb: 'FF000000' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });

    worksheet.getRow(7).height = 25;
    worksheet.getRow(8).height = 25;

    // Ajustar a largura das colunas
    worksheet.columns.forEach((column, index) => {
      column.width = 15;
    });

    const counters = Array.from({ length: 46 }, (_, index) => index + 1);
    const counterRow = worksheet.addRow(counters);

    counterRow.eachCell((cell) => {
      cell.font = { name: 'Arial', bold: true, size: 10, color: { argb: 'FF000000' } };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });

    worksheet.getRow(9).height = 20;

    group.forEach((item) => {
      const itemF = formatItemFields(item); // itemF -> formattedItem

      if (itemF.relacao != null && itemF.relacao == 'FABRICANTE_EXPORTADOR') {
        itemF.fabricante = itemF.exportador;
      }

      // Adiciona uma linha com os dados
      const row = worksheet.addRow([
        itemF.numero_danfe, // 1
        '', // O conteúdo da coluna B será tratado separadamente (para o Produto)
        '',
        '',
        itemF.um, // 5
        itemF.quantidade, // 6
        itemF.valor_unitario, // 7
        itemF.valor_total_mercadoria, // 8 VALOR TOTAL DA MERCADORIA (CIF + II) já calculado na query
        itemF.ato_concessorio || 'N/A', // 9
        itemF.bc_icms, // 10
        getAliquota(itemF.aliquota_icms, itemF.icms_excluded_base, itemF.regime_tributacao_icms), // 11
        itemF.valor_icms, // 12
        itemF.bc_ii, // 13
        getAliquota(itemF.aliquota_ii, itemF.aliquota_ii, itemF.regime_tributacao_ii), // 14
        itemF.valor_ii != null && itemF.valor_ii !== 0 ? itemF.valor_ii : '0,00', // 15
        'X', // 16
        '', // 17
        '0,00', //  (Não deve puxar valor) 18
        '0,00', //  (Não deve puxar valor) 19
        '0,00', //  (Não deve puxar valor) 20
        '0,00', //  (Não deve puxar valor) 21
        '0,00', //  (Não deve puxar valor) 22
        '0,00', //  (Não deve puxar valor) 23
        '0,00', //  (Não deve puxar valor) 24
        '0,00', //  (Não deve puxar valor) 25
        itemF.taxa_siscomex != null && itemF.taxa_siscomex !== 0 ? itemF.taxa_siscomex : '0,00', // 26
        itemF.ncm, // 27
        itemF.lote || '', // 28
        itemF.cfop != null ? itemF.cfop : '0,00', // 29
        'BR02', // 30
        '0,00', // 31
        '0,00', // 32
        '0,00', // 33
        '0,00', // 34
        itemF.di ? itemF.di.replace(/^\d{2}\//, '') : '', // 35 DI formatada sem o ano
        itemF.adicao, // 36
        String(itemF.item).padStart(3, '0'), // 37
        itemF.fabricante, // 38
        '0,00', // 39
        '0,00', // 40
        itemF.bc_ipi != null && itemF.bc_ipi !== 0 ? itemF.bc_ipi : '0,00', // 41
        itemF.bc_pis != null && itemF.bc_pis !== 0 ? itemF.bc_pis : '0,00', // 42
        '0,00', // 44
        itemF.bc_cofins != null && itemF.bc_cofins !== 0 ? itemF.bc_cofins : '0,00', // 45
        '0,00', // 46
        '0,00', // 47
      ]);

      const rowNumber = row.number;
      worksheet.mergeCells(`B${rowNumber}:D${rowNumber}`);

      // Colocar o texto na célula mesclada (B)
      const produtoCell = worksheet.getCell(`B${rowNumber}`);
      produtoCell.value = itemF.descricao_dos_produtos || '';
      produtoCell.alignment = { wrapText: true, horizontal: 'left', vertical: 'top' };

      // Aplicar bordas e formatação em todas as células
      row.eachCell((cell) => {
        cell.font = { name: 'Arial', bold: true, color: { argb: 'FF000000' } };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    });

    // Pulando linhas
    for (let i = 0; i < 8; i++) {
      const row = worksheet.addRow(new Array(46).fill(''));
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        };
      });
    }

    // 1 linha branca
    const whiteRow = worksheet.addRow(new Array(46).fill(''));
    whiteRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      };
    });

    const nveConcatenado = group[0].nve ? group[0].nve.join('; ') : '';

    const sums = calculateGroupSumsFaxe(group);

    const dataFabricacaoFormatted = formatDate(group[0].data_fabricacao);
    const dataVencimentoFormatted = formatDate(group[0].data_validade);

    const dataFabricacaoComLabel = dataFabricacaoFormatted ? `Data de fabricacao: ${dataFabricacaoFormatted}` : '';

    // Verifica se 'po' contém apenas números e transforma a string em número
    let po = group[0].po && /^\d+$/.test(group[0].po) ? parseInt(group[0].po, 10) : group[0].po;

    const additionalData = [
      ['EXPORTADOR:', group[0].exportador],
      ['CODIGO EXPORTADOR:', ''],
      ['PAÍS:', group[0].pais],
      ['N° DO LOTE:', group[0].n_do_lote],
      ['DATA DE FABRICAÇÃO:', dataFabricacaoComLabel],
      ['DATA DE VALIDADE:', dataVencimentoFormatted ? dataVencimentoFormatted : ''],
      ['DATA D.I.:', group[0].data_di],
      ['DATA DESEMBARACO:', group[0].data_desembaraco],
      ['FOB R$:', sums.valoresCIF],
      ['FRETE R$:', sums.fretes],
      ['ATO CONCESSÓRIO:', group[0].ato_concessorio ? group[0].ato_concessorio : 'N/A'],
      ['NVE:', nveConcatenado],
      ['LOCAL DESEMBARACO:', group[0].local_desembaraco],
      ['TRANSPORTADORA:', group[0].transportadora],
      ['PESO BRUTO:', sums.pesosBrutos],
      ['PESO LIQUIDO:', sums.pesosLiquidos],
      ['VOLUMES:', group[0].quantidade_volumes],
      ['ESPECIE:', group[0].especie],
      ['NUMERO DO CONTAINER:', group[0].numero_container ? group[0].numero_container : 'N/A'],
      ['', ''],
      ['DADOS ADICIONAIS:', ''],
      ['REF. DESPACHANTE:', group[0].referencia_despachante],
      ['PO:', po ? po : 'N/A'],
      ['NUMERO DA DI:', group[0].numero_da_di ? group[0].numero_da_di : 'N/A'],
      ['TAXA DOLAR:', formatToBRWithoutThousands(group[0].taxa_dolar, 4)],
      ['VALOR CIF:', sums.valoresCIF],
      ['IMP. IMPORTAÇÃO:', formatToBRWithoutThousands(sums.valoresII, 2)],
      [
        'PIS%:',
        formatToBRWithoutThousands(
          getAliquota(group[0].pisnull, group[0].pis_excluded_base, group[0].regime_tributacao_pis_cofins),
          2
        ),
      ],
      [
        'COFINS%:',
        formatToBRWithoutThousands(
          getAliquota(group[0].cofinsnull, group[0].cofins_excluded_base, group[0].regime_tributacao_pis_cofins),
          2
        ),
      ],
      ['TAXA SISCOMEX:', formatToBRWithoutThousands(group[0].taxa_siscomex, 2)],
      ['MARINHA MERCANTE:', formatToBRWithoutThousands(sums.valoresAFRMM, 2)],
      ['ATA AÉREO:', formatToBRWithoutThousands(0, 2)],
      ['', ''],
    ];

    let startingRow = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;

    additionalData.forEach((detail, index) => {
      const rowNumber = startingRow + index;

      worksheet.mergeCells(`A${rowNumber}:B${rowNumber}`);
      worksheet.mergeCells(`C${rowNumber}:G${rowNumber}`);

      const titleCell = worksheet.getCell(`A${rowNumber}`);
      titleCell.value = `${detail[0]}`;
      titleCell.font = { name: 'Arial', bold: true, size: 10 };
      titleCell.alignment = { horizontal: 'left', vertical: 'middle' };

      const valueCell = worksheet.getCell(`C${rowNumber}`);

      if (Array.isArray(detail[1])) {
        valueCell.value = detail[1].join(', ');
      } else if (typeof detail[1] === 'number') {
        valueCell.value = detail[1];
      } else if (typeof detail[1] === 'string' || detail[1] instanceof Date) {
        valueCell.value = detail[1];
      } else {
        valueCell.value = '';
      }

      // Verificação e remoção do apóstrofo (')
      if (typeof valueCell.value === 'string' && valueCell.value.startsWith("'")) {
        valueCell.value = valueCell.value.slice(1);
      }

      valueCell.alignment = { wrapText: true, horizontal: 'left', vertical: 'middle' };
      valueCell.font = { name: 'Arial', size: 10, bold: true };

      // Aplicar bordas conforme a linha
      if (index === 0) {
        titleCell.border = {
          top: { style: 'thick', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thick', color: { argb: 'FF000000' } },
          right: { style: 'thick', color: { argb: 'FF000000' } },
        };
      } else if (index === 20) {
        titleCell.font = { name: 'Arial', bold: true, size: 10, color: { argb: 'FFFF0000' } };

        titleCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thick', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thick', color: { argb: 'FF000000' } },
          right: { style: 'thick', color: { argb: 'FF000000' } },
        };
      } else if (index === 31) {
        titleCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thick', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thick', color: { argb: 'FF000000' } },
          right: { style: 'thick', color: { argb: 'FF000000' } },
        };
      } else if (index === 32) {
        titleCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thick', color: { argb: 'FFFFFFFF' } },
        };
      } else {
        titleCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thick', color: { argb: 'FF000000' } },
        };
      }

      if (['FOB R$:', 'FRETE R$:', 'VALOR CIF:'].includes(detail[0] as string)) {
        let value = parseFloat(valueCell.value as string);
        valueCell.value = value;
        valueCell.numFmt = '#,##0.00';
      }

      if (['PESO BRUTO:', 'PESO LIQUIDO:'].includes(detail[0] as string)) {
        const formattedValue = parseFloat(valueCell.value as string).toFixed(7);
        valueCell.value = formattedValue;
      }

      // Formatar IMP. IMPORTAÇÃO, PIS%, COFINS%, TAXA SISCOMEX, MARINHA MERCANTE, ATA AÉREO para 2 casas decimais
      if (
        ['IMP. IMPORTAÇÃO:', 'PIS%:', 'COFINS%:', 'TAXA SISCOMEX:', 'MARINHA MERCANTE:', 'ATA AÉREO:'].includes(
          detail[0] as string
        )
      ) {
        valueCell.numFmt = '#,##0.00';
      }
    });

    const observacoesRow = worksheet.addRow([]);
    worksheet.mergeCells(`A${observacoesRow.number}:G${observacoesRow.number}`);

    const observacoesCell = worksheet.getCell(`A${observacoesRow.number}`);
    observacoesCell.value = 'OBSERVAÇÕES';
    observacoesCell.font = { name: 'Arial', bold: true, size: 10, color: { argb: 'FFFF0000' } };
    observacoesCell.alignment = { horizontal: 'left', vertical: 'middle' };
    observacoesCell.border = {
      top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      bottom: { style: 'thick', color: { argb: 'FF000000' } },
      left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
      right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
    };

    const observaocoesData = [
      ['', ''],
      ['', ''],
      ['', ''],
      ['NAO INCIDENCIA DO IMPOSTO SOBRE PRODUTOS', ''],
      ['INDUSTRIALIZADOS-Tipi,CONFORME DECRETO Nº 7.660,', ''],
      ['DE 23 DE DEZEMBRO DE 2011', ''],
      ['', ''],
      ['REDUCAO DE PIS E COFINS', ''],
      ['DE ACORDO COM A LEI 10.865/2004, ART. 8o.,', ''],
      ['PARAGRAFO 11 E DECRETO 6.426/08, ART. 2o.,', ''],
      ['OS PRODUTOS FARMACEUTICOS QUE RELACIONA, ', ''],
      ['CLASSIFICADOS NO CAPITULO 30 DA NCM ENCONTRAM-SE', ''],
      ['COM ALIQUOTA REDUZIDA A 0% PARA O PIS/PASEP', ''],
      ['E COFINS.', ''],
      ['', ''],
      ['', ''],
    ];

    let startingRowTwo = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;

    observaocoesData.forEach((detail, index) => {
      const rowNumber = startingRowTwo + index;

      worksheet.mergeCells(`A${rowNumber}:D${rowNumber}`);

      worksheet.mergeCells(`E${rowNumber}:G${rowNumber}`);

      const titleCell = worksheet.getCell(`A${rowNumber}`);
      titleCell.value = `${detail[0]}`;
      titleCell.font = { name: 'Arial', bold: true, size: 10 };
      titleCell.alignment = { horizontal: 'left', vertical: 'middle' };

      const valueCell = worksheet.getCell(`E${rowNumber}`);

      valueCell.alignment = { wrapText: true, horizontal: 'left', vertical: 'middle' };
      valueCell.font = { name: 'Arial', size: 10, bold: true };

      if (index === 15) {
        titleCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thick', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thick', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thick', color: { argb: 'FF000000' } },
        };
      } else {
        // Restante das linhas: borda branca, exceto a borda direita que será preta
        titleCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thin', color: { argb: 'FFFFFFFF' } },
        };

        valueCell.border = {
          top: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          bottom: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          left: { style: 'thin', color: { argb: 'FFFFFFFF' } },
          right: { style: 'thick', color: { argb: 'FF000000' } },
        };
      }
    });

    // Save the workbook to a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), `Relatório Draft Danfe Faxe - ${processo}.xlsx`);
    });
  });
};

/**
 * Função para formatar valores com duas casas decimais e arredondar.
 */
function formatToTwoDecimals(value: number | string | null | undefined): string | null {
  if (value !== null && value !== undefined) {
    return parseFloat(value.toString()).toFixed(2);
  }
  return '0,00';
}

function formatToDecimals(value: number | string | null | undefined, toFixed: number): string | null {
  if (value !== null && value !== undefined) {
    return parseFloat(value.toString()).toFixed(toFixed);
  }
  return '0,00';
}

function formatToBRWithoutThousands(value: number | string | null | undefined, toFixed: number): number {
  if (value !== null && value !== undefined) {
    // Converte o valor para número, formata com as casas decimais definidas e retorna como número
    return parseFloat(parseFloat(value.toString()).toFixed(toFixed));
  }
  return 0.0;
}

/**
 * Função para aplicar a formatação nos campos específicos.
 */
function formatItemFields(item: DraftDanfeFaxeReportItem): DraftDanfeFaxeReportItem {
  return {
    ...item,
    valor_unitario: parseFloat(formatToDecimals(item.valor_unitario, 6)!), // VALOR UNITÁRIO (campo 7)
    valor_total_mercadoria: parseFloat(formatToTwoDecimals(item.valor_total_mercadoria)!), // VALOR TOTAL DA MERCADORIA (campo 8)
    bc_icms: parseFloat(formatToTwoDecimals(item.bc_icms)!), // BC ICMS (campo 10)
    bc_ii: parseFloat(formatToTwoDecimals(item.bc_ii)!), // BC do II (campo 13)
    outras_despesas: parseFloat(formatToTwoDecimals(item.outras_despesas)!), // OTHER COST (campo 26)
    ipi_other_base: parseFloat(formatToTwoDecimals(item.ipi_other_base)!), // BASE IPI (campo 41)
    pis_other_base: parseFloat(formatToTwoDecimals(item.pis_other_base)!), // BASE PIS (campo 43)
    cofins_other_base: parseFloat(formatToTwoDecimals(item.cofins_other_base)!), // BASE COFINS (campo 45)
    ipi_excluded_base: item.ipi_excluded_base ? parseFloat(formatToTwoDecimals(item.ipi_excluded_base)!) : undefined, // IPI EXCLUDED BASE
    pis_excluded_base: item.pis_excluded_base ? parseFloat(formatToTwoDecimals(item.pis_excluded_base)!) : undefined, // PIS EXCLUDED BASE
    cofins_excluded_base: item.cofins_excluded_base
      ? parseFloat(formatToTwoDecimals(item.cofins_excluded_base)!)
      : undefined, // COFINS EXCLUDED BASE
    icms_other_base: parseFloat(formatToTwoDecimals(item.icms_other_base)!), // ICMS OTHER BASE
    icms_excluded_base: item.icms_excluded_base ? parseFloat(formatToTwoDecimals(item.icms_excluded_base)!) : undefined, // ICMS EXCLUDED BASE
    valor_icms: parseFloat(formatToTwoDecimals(item.valor_icms)!),
    valor_ii: parseFloat(formatToTwoDecimals(item.valor_ii)!),
    taxa_siscomex: parseFloat(formatToTwoDecimals(item.taxa_siscomex)!),
    bc_ipi: parseFloat(formatToTwoDecimals(item.bc_ipi)!),
  };
}

/**
 * Group items by DANFE number.
 * @param {DraftDanfeFaxeReportItem[]} items - DraftDanfeFaxeReportItem[]
 * @returns {DraftDanfeFaxeReportItem[][]}
 */
export const groupItemsByDanfeFaxe = (items: DraftDanfeFaxeReportItem[]) => {
  const groupedItems: { [key: number]: DraftDanfeFaxeReportItem[] } = {};
  items.forEach((item) => {
    if (!groupedItems[item.numero_danfe]) {
      groupedItems[item.numero_danfe] = [];
    }
    groupedItems[item.numero_danfe].push(item);
  });
  return Object.values(groupedItems);
};

/**
 * Calculate the sum of the numeric fields in a group.
 * @param {DraftDanfeFaxeReportItem[]} group - DraftDanfeFaxeReportItem[]
 * @returns {ReportItemsSumsFaxe}
 */
const calculateGroupSumsFaxe = (group: DraftDanfeFaxeReportItem[]) => {
  const sums: ReportItemsSumsFaxe = {
    quantidades: 0,
    valorTotalMercadoria: 0,
    fretes: 0,
    valoresII: 0,
    valoresIPI: 0,
    valoresPIS: 0,
    valoresCOFINS: 0,
    outrasDespesas: 0,
    pesosBrutos: 0,
    pesosLiquidos: 0,
    valoresCIF: 0,
    valoresAFRMM: 0,
  };

  group.forEach((item) => {
    sums.quantidades += item.quantidade;
    sums.valorTotalMercadoria += item.valor_total_mercadoria;
    sums.fretes += item.frete_total;
    sums.valoresII += item.valor_ii;
    sums.valoresIPI += item.valor_ipi;
    sums.valoresPIS += item.valor_pis;
    sums.valoresCOFINS += item.valor_cofins;
    sums.outrasDespesas += item.outras_despesas;
    sums.pesosBrutos += item.peso_bruto_total;
    sums.pesosLiquidos += item.peso_liquido_total;
    sums.valoresCIF += item.valor_cif;
    sums.valoresAFRMM += item.marinha_mercante;
  });

  return sums;
};

const headerConfig = [
  { header: 'COD. PRODUTO', mergeRange: 'A7:A8' },
  { header: 'PRODUTO', mergeRange: 'B7:D8' },
  { header: 'UM', mergeRange: 'E7:E8' },
  { header: 'QUANTIDADE', mergeRange: 'F7:F8' },
  { header: 'VALOR UNITÁRIO', mergeRange: 'G7:G8' },
  { header: 'VALOR TOTAL DA MERCADORIA', mergeRange: 'H7:H8' },
  { header: 'ATO CONCESSÓRIO', mergeRange: 'I7:I8' },
  { header: 'BC ICMS', mergeRange: 'J7:J8' },
  { header: 'ALÍQUOTA ICMS', mergeRange: 'K7:K8' },
  { header: 'VALOR ICMS', mergeRange: 'L7:L8' },
  { header: 'BC do II', mergeRange: 'M7:M8' },
  { header: 'ALÍQUOTA I.I.', mergeRange: 'N7:N8' },
  { header: 'VALOR II', mergeRange: 'O7:O8' },
  { header: 'I.I. STATISTICAL FLAG', mergeRange: 'P7:P8' },
  { header: 'BASE IPI', mergeRange: 'Q7:Q8' },
  { header: 'ALÍQUOTA IPI', mergeRange: 'R7:R8' },
  { header: 'VALOR IPI', mergeRange: 'S7:S8' },
  { header: 'BASE PIS', mergeRange: 'T7:T8' },
  { header: 'ALÍQUOTA PIS', mergeRange: 'U7:U8' },
  { header: 'VALOR PIS', mergeRange: 'V7:V8' },
  { header: 'BASE COFINS', mergeRange: 'W7:W8' },
  { header: 'ALÍQUOTA COFINS', mergeRange: 'X7:X8' },
  { header: 'VALOR COFINS', mergeRange: 'Y7:Y8' },
  { header: 'OTHER COST', mergeRange: 'Z7:Z8' },
  { header: 'NCM', mergeRange: 'AA7:AA8' },
  { header: 'LOTE', mergeRange: 'AB7:AB8' },
  { header: 'CFOP', mergeRange: 'AC7:AC8' },
  { header: 'PLANTA', mergeRange: 'AD7:AD8' },
  { header: 'ICMS TAX LAW', mergeRange: 'AE7:AE8' },
  { header: 'IPI TAX LAW', mergeRange: 'AF7:AF8' },
  { header: 'COFINS TAX LAW', mergeRange: 'AG7:AG8' },
  { header: 'PIS TAX LAW', mergeRange: 'AH7:AH8' },
  { header: 'DI', mergeRange: 'AI7:AI8' },
  { header: 'ADIÇÃO', mergeRange: 'AJ7:AJ8' },
  { header: 'SEQ ADIÇÃO', mergeRange: 'AK7:AK8' },
  { header: 'FABRICANTE', mergeRange: 'AL7:AL8' },
  { header: 'ICMS OTHER BASE', mergeRange: 'AM7:AM8' },
  { header: 'ICMS EXCLUDED BASE', mergeRange: 'AN7:AN8' },
  { header: 'IPI OTHER BASE', mergeRange: 'AO7:AO8' },
  { header: 'IPI EXCLUDED BASE', mergeRange: 'AP7:AP8' },
  { header: 'PIS OTHER BASE', mergeRange: 'AQ7:AQ8' },
  { header: 'PIS EXCLUDED BASE', mergeRange: 'AR7:AR8' },
  { header: 'COFINS OTHER BASE', mergeRange: 'AS7:AS8' },
  { header: 'COFINS EXCLUDED BASE', mergeRange: 'AT7:AT8' },
];

// Função para devolver a alíquota correta com base no regime de tributação
function getAliquota(
  aliquotaNormal?: number,
  aliquotaReduzida?: number,
  regimeTributacao?: string | undefined
): number {
  const normal = aliquotaNormal != null ? aliquotaNormal : 0.0;
  const reduzida = aliquotaReduzida != null ? aliquotaReduzida : 0.0;

  if (regimeTributacao && regimeTributacao.toUpperCase() === 'REDUCAO') {
    return reduzida;
  }

  return normal;
}

function formatDate(dateValue: string | Date | undefined): string {
  if (!dateValue) {
    return 'Data não disponível'; // Retorna um valor padrão quando a data for null ou undefined
  }

  // Garantimos que a data está no formato Date
  const dateObj = new Date(dateValue);

  if (isNaN(dateObj.getTime())) {
    return 'Data inválida'; // Verifica se a data é válida
  }

  // Array com os meses abreviados em inglês
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const day = dateObj.getDate().toString().padStart(2, '0'); // Garantir que o dia tenha 2 dígitos
  const month = monthNames[dateObj.getMonth()]; // Pega o nome do mês abreviado
  const year = dateObj.getFullYear(); // Pega o ano

  return `${day}/${month}/${year}`; // Retorna no formato desejado
}
