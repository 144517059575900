import { Grid } from '@material-ui/core';
import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import { useFormState, useForm } from 'react-final-form';
import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatCnpj } from '../../utils/hooks/form/field/formatters';
import { fetchAllAsync, selectClientes, selectStatus, resetList } from '../../features/cliente/clienteSlice';
import { isAlternativeLoadingStatus } from '../../utils/store/store-utils';
import QCXSelectClienteAutocomplete from '../../shared-components/final-select-cliente-field/QCXSelectClienteAutocomplete';
import QCXFormSubtitle from '../form-title/QCXFormSubtitle';
import QCXFinalBondManager from '../../shared-components/final-gerenciador-relacionamento/QCXFinalBondManager';

export default function QCXSelectManyClientesForm({ isConsult }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm();
  const { values } = useFormState();

  const clientes = useSelector(selectClientes);
  const status = useSelector(selectStatus);
  const isAlternativeLoading = useMemo(() => isAlternativeLoadingStatus(status), [status]);

  useEffect(() => {
    dispatch(fetchAllAsync());
  }, []);

  // path onde esta os dados e define as colunas
  const clienteProps = useMemo(
    () => ({
      name: 'relacaoClientes',
      columns: [
        {
          field: 'id',
          headerName: t('com.muralis.qcx.codigo'),
          headerAlign: 'center',
          align: 'center',
          type: 'string',
          flex: 50,
          valueGetter: ({ row }) => row?.cliente?.id,
        },
        {
          field: 'description',
          headerName: t('com.muralis.qcx.cliente.label'),
          type: 'string',
          flex: 250,
          valueGetter: ({ row }) => row?.cliente?.description,
        },
        {
          field: 'cidade',
          headerName: t('com.muralis.qcx.endereco.cidade'),
          type: 'string',
          flex: 100,
          valueGetter: ({ row }) => row?.cliente?.endereco?.cidade?.description ?? '-',
        },
        {
          field: 'estado',
          headerName: t('com.muralis.qcx.endereco.estado'),
          type: 'string',
          flex: 100,
          valueGetter: ({ row }) => row?.cliente?.endereco?.estado?.description ?? '-',
        },
        {
          field: 'logradouro',
          headerName: t('com.muralis.qcx.endereco.logradouro'),
          type: 'string',
          flex: 100,
          valueGetter: ({ row }) => {
            console.log(row);
            return row?.cliente?.endereco?.logradouro ?? '-';
          },
        },
        {
          field: 'cnpj',
          headerName: t('com.muralis.qcx.empresa.CNPJ'),
          type: 'string',
          flex: 100,
          valueGetter: ({ row }) => formatCnpj(row?.cliente?.pessoa?.cnpj),
        },
        {
          field: 'active',
          headerName: t('com.muralis.qcx.estado.ativo'),
          type: 'boolean',
          flex: 50,
        },
      ],
    }),
    []
  );

  // define os campos que vao interagir com o form
  const clienteFormProps = useMemo(
    () => ({
      rootName: `ignorableFields.cliente`,
      fields: [
        {
          name: 'id',
          label: t('com.muralis.qcx.cliente.label'),
          required: false,
        },
      ],
    }),
    []
  );

  const inserirClienteArrForm = (cliente) => {
    if (!cliente) return;
    const idEmpresa = form.getState()?.values?.id;

    const relacaoCliente = {
      id: uuid(),
      exportadorId: idEmpresa,
      codigoExportadorSiscomex: null,
      cliente: {
        ...cliente,
      },
    };

    const clientesForm = form.getState()?.values?.relacaoClientes;
    if (_.isArray(clientesForm)) {
      form.change('relacaoClientes', [...clientesForm, relacaoCliente]);
    } else {
      form.change('relacaoClientes', [relacaoCliente]);
    }
  };

  const resetForm = () => {
    form.change('ignorableFields.cliente.id', null);
  };

  const setStateUpdateForm = () => {};

  const handleAddCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const clientesIsArray = _.isArray(clientes);
      const idCliente = _.get(currentVinculo, 'id');

      if (!clientesIsArray || (clientesIsArray && clientes.length === 0)) {
        console.log('Sem clientes carregados em memoria para busca !!');
        return;
      }

      if (!idCliente) {
        console.log('Nenhum cliente selecionado !!');
        return;
      }

      const clienteSelecionado = clientes.find((el) => el.id === idCliente);
      if (clienteSelecionado) {
        inserirClienteArrForm(clienteSelecionado);
      } else {
        console.log('Cliente nao encontrado !!');
      }

      resetForm();
    },
    [clientes]
  );

  const handleUpdateCliente = useCallback(
    async (currentVinculo, currentVinculos, handleSuccessAddVinculo) => {
      const clientesIsArray = _.isArray(clientes);
      const idCliente = _.get(currentVinculo, 'id');

      if (!clientesIsArray || (clientesIsArray && clientes.length === 0)) {
        console.log('Sem clientes carregados em memoria para busca !!');
        return;
      }

      if (!idCliente) {
        console.log('Nenhum cliente selecionado !!');
        return;
      }

      if (!_.isArray(currentVinculos)) {
        console.log('Nenhum registro para atualizar !!');
        return;
      }

      const indice = currentVinculos.findIndex((el) => el.id === idCliente);

      if (indice === -1) {
        console.log('Vinculo nao encontrado !!');
        return;
      }

      currentVinculos[indice].cliente = currentVinculo.cliente;

      form.change('relacaoClientes', [...currentVinculos]);
    },
    [clientes]
  );

  return (
    <>
      <Grid item xs={12}>
        <QCXFormSubtitle title="Clientes" />
      </Grid>

      <QCXFinalBondManager
        isParentConsult={isConsult}
        listProps={clienteProps}
        formProps={clienteFormProps}
        handleAdd={handleAddCliente}
        handleUpdate={handleUpdateCliente}
        forceShowDelete
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <QCXSelectClienteAutocomplete
            id="select-field-exportador"
            key="select-field-exportador"
            name="ignorableFields.cliente.id"
            label={t('com.muralis.qcx.cliente.label')}
            initialValues={values}
            disabled={isConsult}
          />
        </Grid>
      </QCXFinalBondManager>
    </>
  );
}
